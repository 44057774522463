var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"ports-card"},[_c('validation-observer',{ref:"modelsCreatePorts"},[_c('b-form',[(!_vm.statisticOnly)?_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Columns:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Columns:","vid":"columns","rules":"max:30|required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"0","autocomplete":"off"},model:{value:(_vm.rawColumns),callback:function ($$v) {_vm.rawColumns=_vm._n($$v)},expression:"rawColumns"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,608164633)})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Rows:","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Rows:","vid":"rows","rules":"max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"0","autocomplete":"off"},model:{value:(_vm.rawRows),callback:function ($$v) {_vm.rawRows=_vm._n($$v)},expression:"rawRows"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,688103301)})],1)],1):_vm._e(),_c('div',{staticClass:"grid-center"},[_c('div',{staticClass:"grid-container",class:{ 'statistic-only': _vm.statisticOnly }},_vm._l((_vm.portsLayout),function(row,columnIndex){return _c('div',{key:columnIndex,staticClass:"grid-row"},_vm._l((row),function(slot,rowIndex){return _c('div',{key:rowIndex},[(!_vm.statisticOnly)?_c('b-dropdown',{staticClass:"dropdown-toggle-ports",class:{
                  'add-margin': ((columnIndex + 1) % 4 === 0 && columnIndex !== 0)
                },attrs:{"toggle-class":"more-dropdown-transparent","dropdown":"","right":""},on:{"shown":function($event){return _vm.activePortHandler(columnIndex, rowIndex)},"hidden":function($event){return _vm.removeActivePort(columnIndex, rowIndex)}},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"grid-block",class:{
                      'placed': slot.port,
                      'active': _vm.activePort === (columnIndex + "-" + rowIndex),
                    },on:{"mouseover":function($event){return _vm.onHoverBlurPort(slot.port, true)},"mouseleave":function($event){return _vm.onHoverBlurPort(slot.port, false)}}})]},proxy:true}],null,true)},[(slot.port && _vm.allPorts.length > 0)?_c('b-dropdown-item',{key:"remove",staticClass:"remove-port",attrs:{"id":"ports-toggle"},on:{"click":function($event){return _vm.removePort(columnIndex, rowIndex, _vm.port)}}},[_vm._v(" Reassign ")]):_vm._e(),(_vm.allPorts.length === 0)?_c('b-dropdown-item',{key:"remove",staticClass:"remove-port",attrs:{"id":"ports-toggle","disabled":""}},[_vm._v(" No Available Ports ")]):_vm._e(),_vm._l((_vm.allPorts.filter(function (port) { return !_vm.usedPorts.has(port.interface); })),function(port){return _c('b-dropdown-item',{key:port.interface,attrs:{"id":"ports-toggle"},on:{"click":function($event){return _vm.handleStatus(columnIndex, rowIndex, port)}}},[_vm._v(" "+_vm._s(port.interface)+" ")])}),_vm._l((_vm.allPorts.filter(function (port) { return _vm.usedPorts.has(port.interface); })),function(port){return _c('b-dropdown-item',{key:port.interface,attrs:{"id":"ports-toggle"},on:{"click":function($event){return _vm.handleStatus(columnIndex, rowIndex, port)}}},[_vm._v(" "+_vm._s(port.interface)+" "),_c('span',{staticClass:"used-port-label"},[_vm._v(_vm._s(_vm.isUsedPort(port) ? '(Used)' : ''))])])})],2):_c('div',{staticClass:"dropdown-toggle-ports mb-50",class:{
                  'add-margin': ((columnIndex + 1) % 4 === 0 && columnIndex !== 0 && columnIndex !== _vm.portsLayout.length - 1)
                },on:{"click":function($event){return _vm.handleStatus(columnIndex, rowIndex, slot)}}},[(slot.port)?_c('div',{staticClass:"grid-block",class:{
                    'placed': slot.port,
                    'active': _vm.activePort === (columnIndex + "-" + rowIndex),
                  },style:(_vm.handleClassForStats(slot)),on:{"mouseover":function($event){return _vm.onHoverBlurPort(slot.port, true)},"mouseleave":function($event){return _vm.onHoverBlurPort(slot.port, false)}}}):_c('div',{staticClass:"transparent-item"})])],1)}),0)}),0)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }