<template>
  <b-card class="ports-card">
    <!-- Form -->
    <validation-observer ref="modelsCreatePorts">
      <b-form>
        <div
          v-if="!statisticOnly"
          class="neighbouring-form-container"
        >
          <b-form-group
            label="Columns:"
            label-class="label-class"
            class="neighbouring-form-group"
          >
            <validation-provider
              #default="{ errors }"
              name="Columns:"
              vid="columns"
              rules="max:30|required|min:1"
            >
              <b-form-input
                v-model.number="rawColumns"
                :state="errors.length > 0 ? false : null"
                placeholder="0"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Rows:"
            label-class="label-class"
            class="neighbouring-form-group"
          >
            <validation-provider
              #default="{ errors }"
              name="Rows:"
              vid="rows"
              rules="max:10"
            >
              <b-form-input
                v-model.number="rawRows"
                :state="errors.length > 0 ? false : null"
                placeholder="0"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div class="grid-center">
          <div
            class="grid-container"
            :class="{ 'statistic-only': statisticOnly }"
          >
            <!-- Render cubes dynamically -->
            <div
              v-for="(row, columnIndex) in portsLayout"
              :key="columnIndex"
              class="grid-row"
            >
              <div
                v-for="(slot, rowIndex) in row"
                :key="rowIndex"
              >

                <b-dropdown
                  v-if="!statisticOnly"
                  toggle-class="more-dropdown-transparent"
                  dropdown
                  right
                  class="dropdown-toggle-ports"
                  :class="{
                    'add-margin': ((columnIndex + 1) % 4 === 0 && columnIndex !== 0)
                  }"
                  @shown="activePortHandler(columnIndex, rowIndex)"
                  @hidden="removeActivePort(columnIndex, rowIndex)"
                >
                  <template v-slot:button-content>
                    <div
                      class="grid-block"
                      :class="{
                        'placed': slot.port,
                        'active': activePort === `${columnIndex}-${rowIndex}`,
                      }"
                      @mouseover="onHoverBlurPort(slot.port, true)"
                      @mouseleave="onHoverBlurPort(slot.port, false)"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="slot.port && allPorts.length > 0"
                    id="ports-toggle"
                    key="remove"
                    class="remove-port"
                    @click="removePort(columnIndex, rowIndex, port)"
                  >
                    Reassign
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="allPorts.length === 0"
                    id="ports-toggle"
                    key="remove"
                    class="remove-port"
                    disabled
                  >
                    No Available Ports
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-for="port in allPorts.filter(port => !usedPorts.has(port.interface))"
                    id="ports-toggle"
                    :key="port.interface"
                    @click="handleStatus(columnIndex, rowIndex, port)"
                  >
                    {{ port.interface }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-for="port in allPorts.filter(port => usedPorts.has(port.interface))"
                    id="ports-toggle"
                    :key="port.interface"
                    @click="handleStatus(columnIndex, rowIndex, port)"
                  >
                    {{ port.interface }} <span class="used-port-label">{{ isUsedPort(port) ? '(Used)' : '' }}</span>
                  </b-dropdown-item>
                </b-dropdown>
                <div
                  v-else
                  class="dropdown-toggle-ports mb-50"
                  :class="{
                    'add-margin': ((columnIndex + 1) % 4 === 0 && columnIndex !== 0 && columnIndex !== portsLayout.length - 1)
                  }"
                  @click="handleStatus(columnIndex, rowIndex, slot)"
                >
                  <div
                    v-if="slot.port"
                    class="grid-block"
                    :style="handleClassForStats(slot)"
                    :class="{
                      'placed': slot.port,
                      'active': activePort === `${columnIndex}-${rowIndex}`,
                    }"
                    @mouseover="onHoverBlurPort(slot.port, true)"
                    @mouseleave="onHoverBlurPort(slot.port, false)"
                  />
                  <div
                    v-else
                    class="transparent-item"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { debounce } from '@/utils/utils'
import {
  BCard,
  VBToggle,
  BFormInput,
  BFormGroup,
  BForm,
  BDropdown,
  BDropdownItem,
  VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BDropdown,
    BDropdownItem,
  },
  props: {
    portsLayout: {
      type: Array,
      default: () => [],
    },
    allPorts: {
      type: Array,
      default: () => [],
    },
    handleShowStatistics: {
      type: Function,
      default: () => {},
    },
    statisticOnly: {
      type: Boolean,
      default: false,
    },
    setPortsLayout: {
      type: Function,
      default: () => {},
    },
    setGridSize: {
      type: Function,
      default: () => {},
    },
    onHoverBlurPort: {
      type: Function,
      default: () => {},
    },
    columns: {
      type: Number,
      default: 0,
    },
    rows: {
      type: Number,
      default: 0,
    },
    updateOnCreate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activePort: null,

      rawColumns: 0,
      rawRows: 0,
      firstLoad: true,
      usedPorts: new Set(),
    }
  },
  watch: {
    rawColumns() {
      if (this.rawColumns) {
        debounce(this.setColumnsAndRows, 500)()
      }
    },
    rawRows() {
      if (this.rawRows && !this.firstLoad) {
        debounce(this.setColumnsAndRows, 500)()
      }
    },
    allPorts() {
      // check if any of the port is deleted then remove it from the layout
      const ports = this.portsLayout.map(row => row.map(column => {
        if (column.port && !this.allPorts.find(port => port.interface === column.port.interface)) {
          return {
            ...column,
            port: '',
          }
        }
        return column
      }))
      this.setPortsLayout(ports)
    },
  },
  created() {
    if (this.updateOnCreate) {
      this.rawColumns = this.columns
      this.rawRows = this.rows

      this.portsLayout.forEach(row => row.forEach(column => {
        if (column.port) {
          this.usedPorts.add(column.port.interface)
        }
      }))
    }
  },
  methods: {
    isUsedPort(port) {
      return this.usedPorts.has(port.interface)
    },
    stylesChange(styleType) {
      switch (styleType) {
        case 'active':
          return {
            'background-color': '#89de25',
            'border-color': '#89de25',
          }
        case 'inactive': {
          return {
            'background-color': '#bdbdbd',
            'border-color': '#bdbdbd',
          }
        }
        case 'v24': {
          return {
            'background-color': '#FFC300',
            'border-color': '#FFC300',
          }
        }
        case 'v48': {
          return {
            'background-color': '#ff8033',
            'border-color': '#ff8033',
          }
        }

        default:
          return {}
      }
    },
    handleClassForStats(port) {
      if (this.statisticOnly && port.stats) {
        const { stats } = port

        if (Number.isFinite(+stats.interfacePoe.poeOutVoltage) && stats.interfacePoe.name) {
          const voltage = +stats.interfacePoe.poeOutVoltage

          if (stats.interface.status !== 'link-ok') {
            return this.stylesChange('inactive')
          }

          if (stats.interfacePoe.poeOutStatus === 'waiting-for-load') {
            return this.stylesChange('active')
          }

          // voltage check
          if (voltage > 44) {
            return this.stylesChange('v48')
          }
          return this.stylesChange('v24')
        } if (stats.interface.status === 'link-ok') {
          return this.stylesChange('active')
        }
        return this.stylesChange('inactive')
      }
      return {}
    },
    setColumnsAndRows() {
      if (!this.firstLoad) {
      // do an array with known number of columns and rows
        const ports = []

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.rawColumns; i++) {
          const row = []
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < this.rawRows; j++) {
            row.push({
              port: '',
              column: i,
              row: j,
            })
          }
          ports.push(row)
        }

        this.setGridSize(this.rawColumns, this.rawRows)
        this.setPortsLayout(ports)
      } else {
        // add column and row key to the existing layout
        const ports = this.portsLayout.map((row, i) => row.map((column, j) => ({
          ...column,
          column: i,
          row: j,
        })))
        this.setPortsLayout(ports)
      }
      this.firstLoad = false
    },
    activePortHandler(columnIndex, rowIndex) {
      this.activePort = `${columnIndex}-${rowIndex}`
    },
    removeActivePort(columnIndex, rowIndex) {
      if (this.activePort === `${columnIndex}-${rowIndex}`) {
        this.activePort = null
      }
    },
    sortByNotUsedPorts(ports) {
      return ports.sort((a, b) => {
        if (this.isUsedPort(a)) {
          return 1
        }
        if (this.isUsedPort(b)) {
          return -1
        }
        return 0
      })
    },
    removePort(columnIndex, rowIndex) {
      const ports = this.portsLayout.map((row, i) => {
        if (i === columnIndex) {
          return row.map((column, j) => {
            if (j === rowIndex) {
              return {
                ...column,
                port: '',
              }
            }
            return column
          })
        }
        return row
      })
      this.setPortsLayout(ports)
      this.usedPorts.delete(this.portsLayout[columnIndex][rowIndex].port.interface)
    },
    handleStatus(columnIndex, rowIndex, port) {
      if (this.statisticOnly) {
        this.handleShowStatistics(columnIndex, rowIndex, port)
        this.activePortHandler(columnIndex, rowIndex)
      } else {
      // if the port is already assigned to the slot - remove it from the old one
        const newPorts = this.portsLayout.map(row => row.map(column => {
          if (column.port && column.port.interface === port.interface) {
            return {
              ...column,
              port: '',
            }
          }
          return column
        }))
        const ports = newPorts.map((row, i) => {
          if (i === columnIndex) {
            return row.map((column, j) => {
              if (j === rowIndex) {
                return {
                  ...column,
                  port,
                }
              }
              return column
            })
          }
          return row
        })
        this.setPortsLayout(ports)

        this.usedPorts.add(port.interface)
      }
    },
  },
}
</script>
